$(function()
{
    $( '.gallery a' ).imageLightbox(
        {
            onStart: 	 function() { overlayOn(); },
            onEnd:	 	 function() { overlayOff(); activityIndicatorOff(); },
            onLoadStart: function() { activityIndicatorOn(); },
            onLoadEnd:	 function() { activityIndicatorOff(); }
        });

    // ACTIVITY INDICATOR

    var activityIndicatorOn = function()
        {
            $( '<div id="imagelightbox-loading"><div></div></div>' ).appendTo( 'body' );
        },
        activityIndicatorOff = function()
        {
            $( '#imagelightbox-loading' ).remove();
        },


    // OVERLAY

        overlayOn = function()
        {
            $( '<div id="imagelightbox-overlay"></div>' ).appendTo( 'body' );
        },
        overlayOff = function()
        {
            $( '#imagelightbox-overlay' ).remove();
        }

    $(window).load(function() {
        setTimeout(function() {
            $( '#imagelightbox-overlay' ).fadeOut('slow', function() {
                $(this).remove();
            });
        }, 1000);
    });
});